<template>
  <div>
    <b-tabs
      content-class="mt-3"
    >
      <b-tab
        title="Dados da conta"
        active
      >
        <AccountsDetailsData />
      </b-tab>
      <b-tab title="Extrato financeiro">
        <AccountsDetailsFinancial
          :items="accountFinancial"
          :id-account="$route.params.id"
        />
      </b-tab>
      <b-tab title="Utilização do plano">
        <AccountsDetailsPlan
          :plan="myPlan"
          :id-account="$route.params.id"
        />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import AccountsDetailsData from './AccountsDetailsData.vue'
import AccountsDetailsFinancial from '@/components/financial-details/FinancialDetails.vue'
import AccountsDetailsPlan from '@/components/plan-details/AccountsDetailsPlan.vue'

export default {
  components: {
    BTabs,
    BTab,

    AccountsDetailsData,
    AccountsDetailsFinancial,
    AccountsDetailsPlan,
  },
  computed: { ...mapGetters({ accountFinancial: 'accounts/getMyPayment', myPlan: 'accounts/getMyPlan' }) },
  created() {
    this.fetchAccountsById(this.$route.params.id)
    this.fetchMyPayment(this.$route.params.id)
    this.fetchMyPlan(this.$route.params.id)
  },
  methods: {
    ...mapActions({ fetchMyPayment: 'accounts/fetchMyPayment', fetchMyPlan: 'accounts/fetchMyPlan', fetchAccountsById: 'accounts/fetchAccountsById' }),
  },
}
</script>
