<script>
/* eslint-disable */

</script>
<template>
  <div>

    <div class="btn-actions-content d-flex">
      <b-button v-if="edit" class="d-flex align-items-center mr-3 button-cancel" variant="outline-primary"
        @click="setEdit(false)">
        <VSIcons name="CloseSquare" width="22" height="22" class="pr-2" />
        <span>Cancelar</span>
      </b-button>
      <b-button v-if="!edit" class="btn-grad d-flex align-items-center" @click="setEdit(true)">
        <VSIcons name="Edit" width="22" height="22" class="pr-2" />
        <span>Editar</span>
      </b-button>
      <b-button v-else class="btn-grad d-flex align-items-center" @click="saveChanges()">
        <VSIcons name="TickSquare" width="22" height="22" class="pr-2" />
        <span>Salvar</span>
      </b-button>
    </div>
    <h6>Tipo de Conta</h6>
    <validation-observer ref="form">
      <div>
        <b-form-group v-slot="{ ariaDescribedby }" label="Pessoa Jurídica">
          <b-form-radio-group v-model="accountData.isLegalPerson" :options="options" :aria-describedby="ariaDescribedby"
            name="radio-inline" />
        </b-form-group>
      </div>
      <h6>Pessoa física</h6>
      <div>
        <b-row>
          <b-col v-if="!accountData.isLegalPerson" md="2">
            <validation-provider v-slot="{ errors }" name="nome" rules="required|alpha_spaces">
              <b-form-group label="Nome completo">
                <b-input-group>
                  <b-form-input id="nome-completo" v-model="accountData.person_full_name" type="text" :disabled="!edit" />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col v-else md="3">
            <validation-provider v-slot="{ errors }" name="nome da empresa" rules="required">
              <b-form-group label="Nome da empresa">
                <b-input-group>
                  <b-form-input id="nome-completo" v-model="accountData.corporate_name" type="text" :disabled="!edit" />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="2">
            <validation-provider v-slot="{ errors }" name="e-mail" rules="required|email">
              <b-form-group label="E-mail">
                <b-input-group>
                  <b-form-input id="email" v-model="accountData.email" type="email" :disabled="!edit" />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col v-if="!accountData.isLegalPerson" md="2">
            <validation-provider v-slot="{ errors }" name="CPF" rules="required|cpf">
              <b-form-group label="CPF">
                <b-input-group>
                  <b-form-input id="cpf" v-model="accountData.cpf" v-mask="'###.###.###-##'" type="text"
                    :disabled="!edit" />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col v-else md="2">
            <validation-provider v-slot="{ errors }" name="CNPJ" rules="required|cnpj">
              <b-form-group label="CNPJ">
                <b-input-group>
                  <b-form-input id="cnpj" v-model="accountData.cnpj" v-mask="'##.###.###/####-##'" type="text"
                    :disabled="!edit" />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col v-if="!accountData.isLegalPerson" md="2">
            <validation-provider v-slot="{ errors }" name="Documento de Identificação" rules="max:50">
              <b-form-group label="Documento de Identificação">
                <b-input-group>
                  <b-form-input id="documento" v-model="accountData.identification_document" :disabled="!edit"
                    type="text" />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col v-if="!accountData.isLegalPerson" md="2">
            <validation-provider v-slot="{ errors }" name="Orgão Emissor" rules="max:50">
              <b-form-group label="Órgão Emissor">
                <b-input-group>
                  <b-form-input id="emissor" v-model="accountData.document_issuer" :disabled="!edit" type="text" />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="2">
            <validation-provider v-slot="{ errors }" name="telephone" rules="phone">
              <b-form-group label="Celular">
                <b-input-group>
                  <b-form-input id="celular" v-model="accountData.telephone" v-mask="maskTelephone(accountData.telephone)"
                    type="text" :disabled="!edit" />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="2">
            <b-form-group label="Aniversário">
              <b-input-group>
                <b-form-input id="aniversario" v-model="accountData.birthday" type="date" :disabled="!edit" />
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col md="2">
            <b-form-group label="Como nos conheceu">
              <b-input-group>
                <b-form-select id="media" v-model="accountData.how_meet_us" :options="medias" :disabled="!edit" />
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
      </div>
    </validation-observer>
    <b-button v-if="accountData.isActive == true" variant="danger" class="btn-delete-account" @click="openModal3">
      Desativar conta
    </b-button>
    <b-button v-if="accountData.isActive == false" variant="success" class="btn-delete-account" @click="reactiveAccount">
      Reativar conta
    </b-button>

    <b-row v-if="getUserData.permission.name === 'Master' && !accountData.isActive" class="mt-4">

      <b-col>
        <b-button variant="primary" class="btn-grad d-flex align-items-center" @click="openModal()">
          Renovação do Plano Trial
        </b-button>
      </b-col>
      <b-button v-if="this.isTrial == true" variant="danger" class="btn-delete-account" @click="openModal2">
        Excluir conta
      </b-button>
    </b-row>
    <VSModal width="500" name="RenewPlanTrial" :draggable="false" />

    <div class="custom-modal" v-if="showModal2" @click.self="closeModal">
      <div class="custom-modal-content">
        <span class="custom-modal-close" @click="closeModal">&times;</span>
        <h5 class="custom-modal-header">ATENÇÃO</h5>
        <h5 class="custom-modal-title">Confirmação de exclusão</h5>
        <p class="custom-modal-text">
          Esta ação irá excluir todos os dados da conta permanentemente.
          Deseja continuar?
        </p>
        <div class="custom-modal-footer">
          <button class="custom-modal-button custom-modal-button-danger" :class="{ 'btn-disabled': modalCountdown > 0 }"
            @click="deleteData" :disabled="modalCountdown > 0">
            {{ modalCountdown > 0 ? `Sim (${modalCountdown})` : 'Sim' }}
          </button>
          <button class="custom-modal-button" @click="closeModal">Não</button>
        </div>
      </div>
    </div>


    <div class="custom-modal" v-if="showModal3" @click.self="closeModal3">
      <div class="custom-modal-content">

        <h5 class="custom-modal-header">ATENÇÃO</h5>
        <h5 class="custom-modal-title">Confirmação de desativação</h5>
        <p class="custom-modal-text">
          Esta ação irá desativar a conta do usuário.
        </p>

        <div class="custom-modal-footer">
          <button class="custom-modal-button custom-modal-button-danger" @click="deactiveAccount">
            sim

          </button>
          <button class="custom-modal-button" @click="closeModal3">Não</button>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BFormGroup, BFormRadioGroup, BFormInput, BInputGroup, BButton, BFormSelect, BRow, BCol,
} from 'bootstrap-vue'
import { mapGetters, mapMutations } from 'vuex'
import VSIcons from '@/assets/icons/VSIcons.vue'
import accounts from '@/services/accounts'
import { maskPhone } from '@/@core/mixins/ui/forms'
import VSModal from '@/components/modals/VSModal.vue'
import axios from 'axios'

export default {
  components: {
    BFormGroup,
    BFormRadioGroup,
    BFormInput,
    BInputGroup,
    BButton,
    BFormSelect,
    BRow,
    BCol,
    VSIcons,
    VSModal,
  },
  mixins: [
    maskPhone,
  ],
  data() {
    return {
      contador: 0,
      userIdAccount: '',
      showModal2: false,
      showModal3: false,
      modalCountdown: 0,
      countdownInterval: null,
      id: '', // Você precisa definir o ID correto aqui
      isTrial: null,
      accountData: {
        person_full_name: '',
        cpf: '',
        cnpj: '',
        email: '',
        telephone: '',
        birthday: '',
        how_meet_us: '',
        isLegalPerson: true,
        isActive: true,
      },
      options: [
        { text: 'Pessoa física', value: false },
        { text: 'Pessoa jurídica', value: true },
      ],
      medias: [
        'Facebook', 'Instagram', 'Google', 'Linkedin', 'Youtube', 'Outros',
      ],
    }
  },
  computed: {
    ...mapGetters({ edit: 'app/getItemTableEdit', getUserData: 'auth/getUserData' }),
  },
  created() {
    this.fetchAccount()
  },
  methods: {

    deactiveAccount() {
  const url = process.env.VUE_APP_API;
  const token = localStorage.getItem('accessToken');
  console.log("CONSOLE", url, token);

  axios
    .put(
      `${url}accounts/cancel/${this.$route.params.id}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => {
      this.toast({
        title: 'Conta desativada com sucesso',
        text: 'Conta desativada com sucesso!',
        variant: 'success',
      });

      setTimeout(() => {
        window.location.reload();
      }, 5000);
    })
    .catch((error) => {
      console.log(error);
    });
},



reactiveAccount() {
  const url = process.env.VUE_APP_API;
  const token = localStorage.getItem('accessToken');

  axios
    .put(
      `${url}accounts/client-reativar/${this.$route.params.id}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => {
      console.log("deuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuu");
      this.toast({
        title: 'Conta reativada com sucesso',
        text: 'Conta reativada com sucesso!',
        variant: 'success',
      });

      setTimeout(() => {
        window.location.reload();
      }, 4000);
    })
    .catch((error) => {
      console.log(error);
    });
},











createdMessage() {
  this.toast({
    title: 'Conta excluida com sucesso',
    text: 'O plano foi excluido com sucesso!',
    variant: 'success'
  })
},

deactiveMessage() {
  this.toast({
    title: 'Conta desativada com sucesso',
    text: 'Conta desativada com sucesso!',
    variant: 'success'
  })
},

reactiveMessage() {
  this.toast({
    title: 'Conta reativada com sucesso',
    text: 'Conta reativada com sucesso!',
    variant: 'success'
  })
},
    ...mapMutations({ setEdit: 'app/SET_ITEM_TABLE_EDIT', setSubTitle: 'app/SET_SUBTITLE' }),
  fetchAccount() {
  accounts.getAccountById(this.$route.params.id).then(res => {
    //vem aqui
    this.accountData = res.data
    console.log("---------------------", this.accountData)
    if (res.data.plan_id >= 4) {
      this.isTrial = true
      console.log("é trial")
    } else {
      this.isTrial = false
      console.log("não é trial")
    }

    this.setSubTitle(res.data.person_full_name || res.data.corporate_name)
  })
},
saveChanges() {
  this.$refs.form.validate().then(success => {
    if (success) {
      accounts.editAccount(this.accountData).then(res => {
        this.setEdit(false)
        this.setSubTitle(res.data.person_full_name || res.data.corporate_name)
        this.toast({
          title: 'Atualizada!',
          text: 'Conta atualizada com sucesso!',
          variant: 'success',
        })
      })
    }
  })
},
openModal() {
  this.showModal('RenewPlanTrial')
},

openModal2() {
  this.showModal2 = true;
  console.log(this.showModal2)
  this.startModalCountdown();
},

openModal3() {
  this.showModal3 = true;
  console.log(this.showModal3)

},

closeModal3() {
  this.showModal3 = false;


},

closeModal() {
  this.showModal2 = false;

  this.modalCountdown = 0;
},

startModalCountdown() {
  this.modalCountdown = 10;
  this.updateModalCountdown();
},

updateModalCountdown() {
  if (this.modalCountdown > 0) {
    setTimeout(() => {
      this.modalCountdown = this.modalCountdown - 1;
      this.updateModalCountdown();
      console.log(this.modalCountdown)
    }, 1000);
  }
},

deleteData() {
  const apiUrl = process.env.VUE_APP_API;
  const token = localStorage.getItem('accessToken');

  try {
    // Aqui você pode adicionar qualquer ação antes da exclusão dos dados
    axios.delete(`${apiUrl}accounts/client-delete/${this.userIdAccount}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(() => {
        console.log('Conta deletada com sucesso');
        this.toast({
          title: 'Conta apagada com sucesso',
          text: 'A conta foi excluída com sucesso!',
          variant: 'success',
        });
      })
      .catch((error) => {
        console.log(error);
      });

    this.closeModal();
  } catch (error) {
    console.log(error);
  }
},
  },




mounted() {

  this.userIdAccount = this.$route.params.id






},
  
   

}
</script>

<style lang="scss" scoped>
.custom-modal {
  position: fixed;
  z-index: 99999999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-modal-content {
  background-color: #fff;
  border-radius: 4px;
  padding: 20px;
  max-width: 400px;
  text-align: center;
  position: relative;
}

.custom-modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  color: #888;
  cursor: pointer;
}

.custom-modal-header {
  background-color: #dc3545;
  color: #fff;
  padding: 8px;
  font-size: 16px;
  font-weight: bold;

  border-radius: 4px;
  margin: 0 1rem 1rem 1rem;
}

.custom-modal-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.custom-modal-text {
  font-size: 16px;
  margin-bottom: 20px;
}

.custom-modal-footer {
  display: flex;
  justify-content: center;
}

.custom-modal-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  margin: 0 10px;
  cursor: pointer;
}

.custom-modal-button:hover {
  background-color: #0069d9;
}

.custom-modal-button-danger {
  background-color: #dc3545;
}

.custom-modal-button-danger:hover {
  background-color: #c82333;




}

//quando tiver disabled
.custom-modal-button[disabled] {
  background-color: #6c757d;
  opacity: 0.85;
  cursor: not-allowed;
}



.custom-modal-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.custom-modal-text {
  font-size: 16px;
  margin-bottom: 20px;
}

.custom-modal-footer {
  display: flex;
  justify-content: center;
}

.custom-modal-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  margin: 0 10px;
  cursor: pointer;
}

.custom-modal-button:hover {
  background-color: #0069d9;
}

.custom-modal-button-danger {
  background-color: #dc3545;
}

.custom-modal-button-danger:hover {
  background-color: #c82333;
}
</style>
